import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 94 110">
    <path stroke="#64FFDA" strokeWidth="4" d="M47 3 2 28.42v52L47 107l45-25.42v-52L47 3Z" />
    <path
      fill="#64FFDA"
      d="M70 53.5V68.7c0 .06.02.13-.05.16-.1.05-.14-.05-.19-.1l-1.06-.99a7905.35 7905.35 0 0 0-10.46-9.8l-.18-.15c-.04-.03-.1-.03-.13 0l-.23.24-5.54 6.49-2.81 3.3-.15.17a.4.4 0 0 0-.1.3V83.42l-.02.46c0 .05-.06.1-.13.1l-.2.01h-3.74c-.08 0-.16 0-.25-.02-.02 0-.05-.03-.06-.06a.4.4 0 0 1-.02-.13v-.81c.06-4.76.06-9.51.08-14.26l-.01-.41c-.1-.12-.18-.25-.29-.36l-5.52-6.44-2.82-3.3-.34-.37c-.04-.05-.12-.05-.17 0-.27.24-.52.5-.78.74l-6.33 6.09-4.12 3.97-.29.25-.04-.01-.03-.03-.02-.1V38.75l.01-.45.02-.04c.03-.02.06-.02.08 0l.53.6 4 4.61c1.5 1.74 3 3.48 4.52 5.22l2.69 3.1.06.07c.03.02.1.01.13-.01l.66-.67 4.7-4.9 2.73-2.83.12-.15a.1.1 0 0 0 0-.08 1.27 1.27 0 0 0-.14-.19L42.34 41l-2.85-3.27-2.85-3.27-2.45-2.81-.15-.18c-.07-.08-.06-.14 0-.2l.19-.15a904.32 904.32 0 0 1 2.42-1.82c.16-.12.16-.12.3.04l.2.2c1.07 1.21 2.14 2.43 3.22 3.63l4.2 4.75 2.08 2.34.1.1c.03.02.11.02.14 0l.35-.38 4.58-4.99 3.82-4.17 1.45-1.58c.06-.08.13-.14.2-.2.04-.03.08-.05.13-.01l.16.1 2.45 1.86.08.06c.08.06.09.12.03.19l-.26.33-3.62 4.31-3.54 4.21-2.7 3.21c-.05.07-.1.15-.17.22-.05.07-.04.13.03.2l.13.14 4.17 4.36 3.17 3.31c.1.12.23.22.34.33h.08l.14-.13 2.38-2.7 2.86-3.25 3.54-4.03 3.08-3.5a.85.85 0 0 1 .14-.13s.03 0 .04.02c.05.03.03-.04.03.02l.02.14v15.2Zm-14.65 1.34-.1-.13-2.14-2.23-3.19-3.35-2.65-2.78-.18-.15h-.08a1102.76 1102.76 0 0 0-3.44 3.63c-1.43 1.54-2.88 3.07-4.32 4.6l-.6.66c-.03.03-.04.08 0 .12l.23.29 2.76 3.27 4.22 4.97 1.06 1.24c.02.03.1.03.13 0l.13-.13 1.2-1.47 3.2-3.88 2.9-3.51.85-1.05.02-.1ZM28 54.04V59l.01.4c0 .04.02.06.06.06l.05-.01.18-.15 4.66-4.36.2-.2c.03-.04.02-.09 0-.13l-.1-.15-.82-.96-2.84-3.33-1.15-1.34c-.05-.06-.12-.1-.18-.15l-.04.01-.03.22v5.11Zm37.68.11v-4.93l-.01-.45-.03-.03c-.04-.04-.07-.02-.36.33l-.03.03-4.55 5.35a.83.83 0 0 0-.11.16c-.01.02-.01.06.01.08l.25.27 3.57 3.47 1.04 1c.04.05.1.08.15.1.01.01.04 0 .05-.01.05-.07.03-.15.03-.22v-5.15Z"
    />
  </svg>
);

export default IconLogo;
